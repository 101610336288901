.app {
  font-family: 'Lato', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f8f8;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.header {
  background-color: #1b1a21;
  color: #fff;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 0;
  gap: 20px;
}

.logo {
  height: 80px;
}

.header-title {
  font-size: 2.5em;
  margin: 0;
}

.status {
  background-color: rgb(240, 240, 240);
  color: rgb(105, 105, 105);
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  font-weight: bold;
}

.main {
  padding: 20px;
}

.software-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.software-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 30px;
  text-align: left;
  border: 1px solid #eee;
}

.software-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.software-title {
  margin: 0;
  font-size: 1.8em;
}

.version-select {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.version-select label {
  font-weight: bold;
  margin-bottom: 5px;
}

select {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  appearance: none;
  background-color: #f9f9f9;
}

.packages {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.package {
  background-color: rgba(0, 0, 0, 0.025);
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.package-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
}

.package-title {
  margin: 0;
  font-size: 1.4em;
}

.download-button {
  background-color: #aa00ff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
  width: 100%;
}

.download-button:hover {
  background-color: #311b92;
}

.copy-link-button {
  background-color: rgb(105, 105, 105);
  color: #fff;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s;
  font-weight: bold;
  cursor: pointer;
  margin-top: 5px;
  text-align: center;
  width: 100%;
}

.copy-link-button:hover {
  background-color: rgb(77, 77, 77);
}

.sha {
  font-size: 0.7em;
  color: #666;
  word-break: break-all;
  margin-top: 15px;
  text-align: center;
}

.ops-button {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.2);
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-end;
  margin-top: 20px;
  font-size: 1em;
  transition: background-color 0.3s;
  font-weight: 400;
}

.ops-button:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: #1b1a21;
}

.ops-info {
  background-color: rgba(0, 0, 0, 0.2);
  color: #1b1a21;
  border: none;
  padding: 20px;
  border-radius: 8px;
  margin-top: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.ops-download {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
}

.ops-download a {
  background-color: #aa00ff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s;
  font-weight: bold;
  text-align: center;
}

.ops-download a:hover {
  background-color: #311b92;
}

.ops-version-input {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}

/* Media query for smaller screens (e.g., mobile phones) */
@media (max-width: 700px) {
  .header {
    flex-direction: column;
  }

  .logo {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .header-text {
    text-align: center;
  }

  .header-title {
    font-size: 2em;
  }

  .software-grid {
    grid-template-columns: 1fr;
  }

  .package-header {
    flex-direction: column;
    align-items: stretch;
  }

  .download-button,
  .copy-link-button {
    margin-top: 5px;
  }
}